<template>
  <app-form-select
    v-model="model"
    class="min-w-[150px]"
    :clearable="clearable"
    :hide-details="hideDetails"
    item-title="label"
    item-value="label"
    :items="items"
    label="Type"
    :readonly="readonly"
  />
</template>

<script lang="ts" setup>
const publicModel = defineModel<boolean | undefined>("public", {
  default: undefined,
});
const freeModel = defineModel<boolean | undefined>("free", {
  default: undefined,
});

defineProps<{
  hideDetails?: boolean;
  clearable?: boolean;
  readonly?: boolean;
  required?: boolean;
}>();

const items = [
  {
    label: "Inter-entreprise",
    public: true,
    free: false,
  },
  {
    label: "Intra-entreprise",
    public: false,
    free: false,
  },
  {
    label: "Public marketing event",
    public: true,
    free: true,
  },
  {
    label: "Private marketing event",
    public: false,
    free: true,
  },
] as const satisfies {
  label: string;
  public: boolean;
  free: boolean;
}[];

const model = computed<(typeof items)[number] | undefined>({
  get() {
    return items.find(
      (item) =>
        item.free === freeModel.value && item.public === publicModel.value,
    );
  },
  set(selection) {
    publicModel.value = selection?.public;
    freeModel.value = selection?.free;
  },
});
</script>
